import React from "react"
import styled from "styled-components"
import parse from 'html-react-parser'
import { styles, TitleTable } from './styles'
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const ExpansionPanel = withStyles({
    root: {
      border: '1px solid ' + styles.colorBlack,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
    disabled: {
        border: '0',
        backgroundColor: 'transparent !important',
    },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: styles.colorPrimary,
        padding: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
        }
    },
    expanded: {},
    disabled: {
        backgroundColor: styles.colorBlack,
        opacity: "1 !important",
    }
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
    root: {
        padding: '0',
    },
 
})(MuiExpansionPanelDetails);

const Pane = styled.div`
    display: block;

    &.hidden {
        display: none;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    width: 100%;
    position: relative;

    table {
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        height: auto !important;
        width: 100%;
        table-layout: fixed;

        @media (max-width: 768px) {
            border: 0;
        }

        thead {

            tr {
                height: auto !important;
                background-color: ${styles.colorPrimary};
            }

            th {
                height: auto !important;
                width: auto !important;
            }

            @media (max-width: 768px) {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }

        tbody {
            tr {
                height: auto !important;

                &:nth-child(2n + 1) {
                    background-color: #fff;
                }
    
                &:nth-child(2n) {
                    background-color: #f9f9f9;
                }

                &:hover {
                    background-color: #f3f3f3;
                }
            }

            td {
                height: auto !important;
                width: auto !important;
            }
        }
        
        th, td {
            text-align: center;
            font-size: 14px;

            @media (max-width: 768px) {
                border-bottom: 1px solid #ddd;
                display: block;
                font-size: .8em;
                text-align: right;

                &::before {
                    content: attr(data-label);
                    float: left;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }

        tr {
            @media (max-width: 768px) {
                border-bottom: 3px solid #ddd;
                display: block;
                margin-bottom: .625em;
            }
        }

        a {
            color: ${styles.colorSecondary};
            text-decoration: none;
        }
    }
`;

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            panelExpanded: false,
            panelDisabled: false,
            // hidden: true,
        }
    }

    updateState() {
        console.log("state update")
        if(window.innerWidth < 768) {
            this.setState({ panelExpanded: false, panelDisabled: false, });
        } else {
            this.setState({ panelExpanded: true, panelDisabled: true });
        }
    }

    clickHandler(e) {
        if (!this.state.panelDisabled && (e.target.classList[2] === 'titleTable')) {
            this.setState(prevState => ({ panelExpanded: !prevState.panelExpanded }))
        }
    }

    // show() {
    //     this.setState({hidden : false});
    // }

    componentDidMount() {
        //var that = this;
        this.updateState();
        window.addEventListener("resize", this.updateState.bind(this));
        // setTimeout(function() {
        //     that.show();
        // }, 1000);
        // console.log(window.location.href);
        // if (window.location.href.includes("#kontakt")) {
        //     console.log("true");
        //     window.scrollTo({
        //         top: document.getElementById("#kontakt").offsetTop,
        //         behavior: "smooth"
        //     });
        // } else {
        //     console.log("false");
        // }
    }

    

    render() {
// const Table = ({ title, content }) => {
        const title = this.props.title;
        const content = this.props.content
        const subcontent = this.props.subcontent;
        const table = parse(content);
        const subtable = parse(subcontent);
        
        return (
            <ExpansionPanel defaultExpanded={false} expanded={this.state.panelExpanded} disabled={this.state.panelDisabled} onClick={(e) => this.clickHandler(e)}>
                <ExpansionPanelSummary aria-controls="panel1a-content" id="panel1a-header">
                    <TitleTable className={"titleTable"} colorMD={"#fff"} alignSM={"center"} p={"25px 0"} pSM={"35px 0"} style={{width: "100%"}}>{title}</TitleTable>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Pane className={this.state.hidden ? "hidden" : ""}>
                        <Content>{table}</Content>
                        <div style={{backgroundColor: styles.colorBlack}}>
                            <TitleTable color={"#fff"} alignSM={"center"} p={"25px 0"} pSM={"35px 0"} style={{width: "100%"}}>Regionalne</TitleTable>
                        </div>
                        <Pane>
                            <Content>{subtable}</Content>
                        </Pane>
                    </Pane>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}

export default Table