import React from "react"
import styled from "styled-components"
import { styles } from "./styles"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => (props.padding ? props.padding : '0')};
`

const TextHeader = styled.h2`
  max-width: 510px;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${props => (props.firstColor ? props.firstColor : styles.colorBlack)};
  margin-bottom: 10px;

  span {
    color: ${props => (props.secondColor ? props.secondColor : styles.colorSecondary)};
  }
`

const sectionHeader = ({ firstColor, secondColor, padding, children }) => {
  const [first, second] = divideText({children});
  return (
    <>
      <Content padding={padding}>
            <TextHeader firstColor={firstColor} secondColor={secondColor}>{first} <span>{second}</span></TextHeader>
      </Content>
    </>
  )
}

function divideText(data) {
  if (data) {
    const text = data.children;
    const array = text.split(" ");
    const p1 = array.slice(0, array.length / 2);
    const p2 = array.slice(array.length / 2);
    const normalText = p1.join(" ");
    const coloredText = p2.join(" ")

    return [normalText, coloredText];
  }
}

export default sectionHeader
